import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useRollbar } from '@rollbar/react'
import { isAxiosError } from 'axios'
import {
  GetPrintifyOrderFunction,
  PrintifyBlueprint,
  PrintifyVariantProvider,
} from '../../clients/fagl-server/types/printify'
import ApiClient from '../../ApiClient'
import config from '../../config'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import {
  CartItem,
  Provider,
} from '../../clients/fagl-server/types/photoProductsCart'

function createPhotoUrlsForBlueprintId(blueprintId: number, number: number) {
  const urls = []
  for (let i = 1; i <= number; i++) {
    urls.push(`/photo-products/${blueprintId}_${i}.jpg`)
  }
  return urls
}

const blueprintIdToPhotoUrls: Record<number, string[]> = {
  68: createPhotoUrlsForBlueprintId(68, 4),
  425: createPhotoUrlsForBlueprintId(425, 4),
  616: createPhotoUrlsForBlueprintId(616, 4),
  1115: createPhotoUrlsForBlueprintId(1115, 4),
  1126: createPhotoUrlsForBlueprintId(1126, 4),
  1151: createPhotoUrlsForBlueprintId(1151, 4),
}

const logs: { [key: string]: string[] } = {}

export const useBlueprintId = () => {
  const { blueprintId } = useParams()

  if (!blueprintId) {
    throw new Error('No product id provided')
  }

  return parseInt(blueprintId, 10)
}

async function getBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url, { method: 'GET' })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const blob = await response.blob()

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
      reader.onerror = () => {
        reject(new Error('Failed to read the Blob as Base64'))
      }
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to fetch and convert to Base64: ${error.message}`)
    } else {
      throw new Error('Failed to fetch and convert to Base64: Unknown error')
    }
  }
}

const timestamp = () => {
  return new Date().toISOString()
}

const addLog = (url: string, message: string) => {
  if (!config.isPrintifyPreviewLogsActive) {
    return
  }
  if (!logs[url]) {
    logs[url] = []
  }
  logs[url].push(`${timestamp()}: ${message}`)
}

async function fetchTimesUntilImageChanged(
  url: string,
  times: number,
  delay = 1000,
  initialBase64: string | null = null
) {
  let initialValue: string | null = initialBase64

  let i = 0
  addLog(url, 'Starting to fetch image')

  while (i <= times) {
    try {
      addLog(url, `Fetching image. Iteration: ${i}`)
      const value = await getBase64(url)
      if (!initialValue) {
        addLog(url, `Set URL initial value`)

        initialValue = value
      } else {
        const hasImageChanged = value !== initialValue
        addLog(url, `image has changed`)

        if (hasImageChanged) {
          return {
            isDoneTrying: true,
            isPreviewReady: true,
            times: i,
            base64: value,
            possiblySucceededOnFirstLoad: false,
          }
        } else if (i === times) {
          addLog(url, `reached max iterations: ${times}`)
          return {
            isDoneTrying: true,
            isPreviewReady: false,
            times: i,
            base64: value,
            possiblySucceededOnFirstLoad: true,
          }
        }
      }
    } catch (err) {
      console.error(err)
      addLog(url, `Error fetching image: ${err}`)
    }
    await new Promise((resolve) => setTimeout(resolve, delay))
    i++
  }

  addLog(
    url,
    `reached max iterations but skipped the max iterations check: ${times}`
  )
  return {
    isDoneTrying: true,
    isPreviewReady: false,
    times: i,
    possiblySucceededOnFirstLoad: true,
  }
}

export type PreviewUrlState = {
  isPreviewReady: boolean
  isDoneTrying: boolean
  isDefault: boolean
  url: string
  base64: string
  possiblySucceededOnFirstLoad: boolean
}

export default function usePrintify(
  blueprintId: number,
  api: ApiClient,
  editedCartItem: CartItem | null
) {
  const rollbar = useRollbar()
  const {
    photoProducts: {
      printify: { recordPreviewSuccess, recordPreviewFailure },
    },
  } = useRecordUserAction(api)

  const [showNotAllLoadedError, setShowNotAllLoadedError] = useState(true)
  const [isGettingOrder, setIsGettingOrder] = useState(false)
  const [isGettingPreview, setIsGettingPreview] = useState(false)

  const [isGettingPreviewComplete, setIsGettingPreviewComplete] =
    useState(false)
  const [blueprintLoadError, setBlueprintLoadError] = useState<string>('')
  const [isLoading, setIsLoading] = useState(true)
  const [blueprint, setBlueprint] = useState<PrintifyBlueprint | null>(null)
  const [variantProviders, setVariantProviders] = useState<
    PrintifyVariantProvider[]
  >([])

  const [isCreatingBaseOrder, setIsCreatingBaseOrder] = useState(false)
  const metadata = editedCartItem?.metadata

  const [usedImageCdnUrl, setUsedImageCdnUrl] = useState<string | null>(
    metadata?.usedImages[0].usedUrl ?? null
  )

  const [originalImageCdnUrl, setOriginalImageCdnUrl] = useState<string | null>(
    metadata?.usedImages[0].originalUrl ?? null
  )

  const [usedImageBase64, setUsedImageBase64] = useState<string | null>(null)

  const [baseOrderId, setBaseOrderId] = useState<string | null>(null)
  const [itemIdentifier, setItemIdentifier] = useState<string | null>(
    editedCartItem?.itemIdentifier ?? null
  )

  const [variantId, setVariantId] = useState<number | null>(
    editedCartItem?.metadata.variantId ?? null
  )

  const [orderState, setOrderState] = useState<{
    quantity: number
    printProviderId: number | null
  }>({
    quantity: editedCartItem?.quantity ?? 1,
    printProviderId: editedCartItem?.metadata.printProviderId ?? null,
  })

  const [previewUrlState, setPreviewUrlState] = useState<
    Record<string, PreviewUrlState>
  >({})

  const [fetchLogs, setFetchLogs] = useState<{ [key: string]: string[] }>({})

  const resetState = useCallback(() => {
    setPreviewUrlState({})
    setFetchLogs({})
    setIsGettingPreviewComplete(false)
    setBlueprintLoadError('')
    setIsLoading(true)
    setBlueprint(null)
    setVariantProviders([])
  }, [])

  const previewPhotosList = Object.values(previewUrlState)

  const loadBlueprint = useCallback(
    async (id: number) => {
      setBlueprintLoadError('')
      try {
        const { blueprint: loadedBlueprint, variantProviders } =
          await api.printify.getBlueprintsAndVariants(id)
        const firstProvider = variantProviders[0]
        const firstProviderId = firstProvider.id
        const firstProviderVariants = firstProvider.variants
        const firstVariantId = firstProviderVariants[0].id
        setVariantId(editedCartItem?.metadata.variantId ?? firstVariantId)
        setOrderState({
          ...orderState,
          printProviderId:
            editedCartItem?.metadata.printProviderId ?? firstProviderId,
        })
        setVariantProviders(variantProviders)
        setBlueprint(loadedBlueprint)
      } catch (err) {
        const error = err as Error
        if (isAxiosError(err)) {
          if (err.response?.status === 404) {
            setBlueprintLoadError('NOT_FOUND')
          }
        } else {
          console.error(err)
          rollbar.error('unexpected error loading Printify blueprint', error)
          setBlueprintLoadError(error.message)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [blueprintId, blueprint, setBlueprint, setVariantProviders, setIsLoading]
  )

  const uploadOriginalImage = useCallback(async (base64: string) => {
    const { url } = await api.uploadBase64Image({
      base64,
      uuid: '',
      name: 'test',
    })

    setOriginalImageCdnUrl(url)
  }, [])

  const createBaseOrder = useCallback(
    async (newUsedImageBase64: string, updatedVariantId: number | null) => {
      setVariantId(updatedVariantId)
      // If we already have a base order ID, we should not create a new one
      if (
        baseOrderId &&
        newUsedImageBase64 === usedImageBase64 &&
        updatedVariantId === variantId
      ) {
        return baseOrderId
      }

      setIsGettingPreviewComplete(false)
      setPreviewUrlState({})
      setUsedImageBase64(newUsedImageBase64)
      setIsCreatingBaseOrder(true)

      if (!variantId || !orderState.printProviderId) {
        return
      }

      let imageUrl = ''

      try {
        // TODO: decide on a naming convention for the files
        const { url } = await api.uploadBase64Image({
          base64: newUsedImageBase64,
          uuid: '',
          name: 'test',
        })
        imageUrl = url
        setUsedImageCdnUrl(imageUrl)
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error uploading base 64 image', err as Error)
        console.error(err)
      }

      if (!imageUrl) {
        setIsGettingOrder(true)
        setIsCreatingBaseOrder(false)
        return
      }

      try {
        const response = await api.printify.createOrder({
          lineItems: [
            {
              print_provider_id: orderState.printProviderId,
              blueprint_id: blueprintId,
              variant_id: updatedVariantId ?? variantId,
              print_areas: {
                front: imageUrl,
              },
              quantity: orderState.quantity,
            },
          ],
        })

        await loadOrder(response.id)
        return response.id
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error(
          'Error creating Printify order for preview purposes',
          err as Error
        )
        console.error(err)
      } finally {
        setIsCreatingBaseOrder(false)
      }
    },
    [
      blueprintId,
      orderState,
      baseOrderId,
      usedImageBase64,
      setIsCreatingBaseOrder,
      api,
      rollbar,
    ]
  )

  const getPreview = useCallback(
    async (
      images: {
        src: string
        is_default: boolean
        initialBase64: string
      }[]
    ) => {
      setIsGettingPreview(true)
      setShowNotAllLoadedError(false)

      const initialStateForProcessedImages = Object.fromEntries(
        images.map((image) => [
          image.src,
          {
            isDoneTrying: false,
            isPreviewReady: false,
            isDefault: image.is_default,
            url: image.src,
            base64: image.initialBase64,
            possiblySucceededOnFirstLoad: false,
          },
        ])
      )

      // Since we are fetching potentially only a subset of the images, we
      // need to merge their fresh state with the existing state.
      setPreviewUrlState((state) => ({
        ...state,
        ...initialStateForProcessedImages,
      }))

      const handleUrl = async (url: string, initialBase64: string) => {
        const {
          isDoneTrying,
          isPreviewReady,
          times,
          base64 = '',
          possiblySucceededOnFirstLoad,
        } = await fetchTimesUntilImageChanged(
          url,
          10,
          1500,
          initialBase64 || null
        )
        addLog(
          url,
          `Fetch complete. isDoneTrying: ${isDoneTrying}, isPreviewReady: ${isPreviewReady}, times: ${times}, isDefault: ${previewUrlState[url]?.isDefault}`
        )

        setPreviewUrlState((prev) => ({
          ...prev,
          [url]: {
            isDoneTrying,
            isPreviewReady,
            isDefault: prev[url].isDefault,
            url,
            base64,
            possiblySucceededOnFirstLoad,
          },
        }))
      }

      const promises = images.map((src) =>
        handleUrl(src.src, src.initialBase64)
      )
      await Promise.all(promises)
      setFetchLogs(logs)
      setIsGettingPreview(false)
    },
    [
      setPreviewUrlState,
      setIsGettingPreview,
      setShowNotAllLoadedError,
      previewUrlState,
      rollbar,
    ]
  )

  const regeneratePreview = useCallback(async () => {
    setShowNotAllLoadedError(false)

    // const previewPhotosWhichAreNotReady = previewPhotosList.filter(
    //   (preview) => !preview.isPreviewReady
    // )

    // const payloadForGetPreviewForNotReadyPhotos =
    //   previewPhotosWhichAreNotReady.map((preview) => ({
    //     src: preview.url,
    //     is_default: preview.isDefault,
    //     initialBase64: preview.base64,
    //   }))

    const payloadForGetPreviewForNotReadyPhotos = previewPhotosList.map(
      (preview) => ({
        src: preview.url,
        is_default: preview.isDefault,
        initialBase64: '',
      })
    )

    getPreview(payloadForGetPreviewForNotReadyPhotos)
  }, [previewPhotosList])

  const loadOrder: GetPrintifyOrderFunction = useCallback(
    async (orderId) => {
      // Loading the base order id means we stored it in the URL and loaded a page
      // that requires it.
      // If someone then navigated to a page that does not include the base order ID in
      // the URL, we should not load the order again.
      setBaseOrderId(orderId)

      const response = await api.printify.getOrder(orderId)
      const { line_items: lineItems } = response
      if (!lineItems[0]) {
        return response
      }

      setItemIdentifier(lineItems[0].product_id)

      const { images } = await api.printify.getProduct(lineItems[0].product_id)

      // This is the first time we are getting the preview and so there is no
      // initial base64 to compare with
      await getPreview(images.map((image) => ({ ...image, initialBase64: '' })))
      return response
    },
    [
      api,
      setBaseOrderId,
      setPreviewUrlState,
      isGettingOrder,
      setIsGettingPreview,
      getPreview,
    ]
  )

  const updateQuantityAndVariant = useCallback(
    (quantity: number, variantId: number) => {
      setOrderState({
        ...orderState,
        quantity,
      })
      setVariantId(variantId)
    },
    [orderState, setOrderState, setVariantId]
  )

  useEffect(() => {
    if (blueprintId && blueprintId !== blueprint?.id) {
      loadBlueprint(blueprintId)
    }
  }, [blueprintId, blueprint])

  const previewStatus = useMemo(() => {
    if (previewPhotosList.length === 0) {
      return { logPayload: { numberOfPhotos: 0 }, status: 'PENDING' }
    }

    const readyPreviews = previewPhotosList.filter(
      (preview) => preview.isPreviewReady && preview.base64
    )

    const areAllPreviewsReady =
      readyPreviews.length === previewPhotosList.length

    const areAllDoneTrying = previewPhotosList.every(
      (preview) => preview.isDoneTrying
    )

    if (!areAllDoneTrying) {
      return {
        status: 'PENDING',
        logPayload: {
          numberOfPhotos: previewPhotosList.length,
          numberOfPhotosReady: readyPreviews.length,
        },
      }
    }

    const areZeroPreviewsReady = readyPreviews.length === 0
    const possiblySucceededOnFirstLoad = previewPhotosList.some(
      (preview) => preview.possiblySucceededOnFirstLoad
    )

    const logPayload = {
      numberOfPhotos: previewPhotosList.length,
      numberOfPhotosReady: readyPreviews.length,
      possiblySucceededOnFirstLoad,
    }
    if (
      areAllPreviewsReady ||
      (areZeroPreviewsReady && possiblySucceededOnFirstLoad)
    ) {
      return { status: 'SUCCESS', logPayload }
    } else {
      return { status: 'FAILURE', logPayload }
    }
  }, [previewPhotosList])

  useEffect(() => {
    const { status, logPayload } = previewStatus
    if (status !== 'PENDING' && !isGettingPreviewComplete) {
      if (status === 'SUCCESS') {
        recordPreviewSuccess(logPayload)
      } else if (status === 'FAILURE') {
        setShowNotAllLoadedError(true)
        recordPreviewFailure(logPayload)
        rollbar.warning('Printify previews not ready', logPayload)
      }
      setIsGettingPreviewComplete(true)
    }
  }, [previewStatus, isGettingPreviewComplete])

  const blueprintWithCustomPhotos = blueprint
    ? {
        ...blueprint,
        images: blueprintIdToPhotoUrls[blueprintId] || blueprint.images || [],
      }
    : null

  const currency = blueprint?.currency || 'usd'
  const variants = blueprint?.variants || {}
  const blueprintTitle = blueprint?.title || ''
  const selectedVariantId = variantId
  const selectedVariant = selectedVariantId ? variants[selectedVariantId] : null
  const previewCdnUrl =
    previewPhotosList.find((photo) => photo.isDefault)?.url ||
    previewPhotosList[0]?.url

  const addItemToCartPayload = {
    itemIdentifier,
    provider: Provider.PRINTIFY,
    metadata: {
      baseOrderId,
      blueprintId,
      printProviderId: orderState.printProviderId ?? undefined,
      variantId,
      price: selectedVariant?.price || 0,
      title: selectedVariant?.title || blueprintTitle,
      previewCdnUrl,
      usedImages: [
        {
          usedUrl: usedImageCdnUrl,
          originalUrl: originalImageCdnUrl,
        },
      ],
    },
  }

  const variantsArray = useMemo(() => {
    return variantProviders[0]?.variants || []
  }, [variantProviders])

  return {
    currency,
    selectedVariant,
    isLoading,
    blueprint: blueprintWithCustomPhotos,
    orderState,
    loadBlueprint,
    setOrderState,
    blueprintId,
    createBaseOrder,
    previewPhotosList,
    isCreatingBaseOrder,
    isGettingOrder,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    updateQuantityAndVariant,
    baseOrderId,
    addItemToCartPayload,
    uploadOriginalImage,
    variantId,
    variants: variantsArray,
    blueprintLoadError,
    resetState,
    usedImageCdnUrl,
    originalImageCdnUrl,
  }
}
