import { useCallback, useMemo, useState } from 'react'
import {
  PrintifyBlueprint,
  PrintifyBlueprintVariant,
} from '../../clients/fagl-server/types/printify'
import Button from '../../shared/buttons/Button'
import Collapsible from '../../shared/Collapsible'
import ProductPhotos from './components/ProductPhotos'
import QuantitySelector from './components/QuantitySelector'
import VariantSelectionSection from './components/VariantSelectionSection'

export default function ProductPage({
  next,
  previous,
  blueprint,
  variants,
  initialVariantId,
  initialQuantity,
  price,
  salePrice,
  openTermsOfUsePage,
  updateQuantityAndVariant,
}: {
  next: (payload: { quantity: number; variantId: number }) => void
  previous: () => void
  blueprint: PrintifyBlueprint
  variants: PrintifyBlueprintVariant[]
  initialVariantId: number | null
  initialQuantity: number
  price: string
  salePrice: string
  openTermsOfUsePage: () => void
  updateQuantityAndVariant: (quantity: number, variantId: number) => void
}) {
  const [quantity, setQuantity] = useState(initialQuantity)
  const [variantId, setVariantId] = useState<number | null>(initialVariantId)

  const onNextClick = useCallback(() => {
    if (!variantId) {
      return
    }
    next({ quantity, variantId })
  }, [quantity, variantId, next])

  const updateVariantId = useCallback(
    (variantId: number) => {
      setVariantId(variantId)
      updateQuantityAndVariant(quantity, variantId)
    },
    [quantity, updateQuantityAndVariant]
  )

  const variantTitle = useMemo(() => {
    return variantId ? blueprint.variants[variantId].title : blueprint.title
  }, [variantId, blueprint.variants, blueprint.title])

  return (
    <div className="h-screen relative flex flex-col pt-8">
      <header className="flex-0 px-4 space-y-2 mb-4">
        <h1 className="font-bold text-2xl">{variantTitle}</h1>
        <div className="flex space-x-1 items-end uppercase">
          <span className="line-through">{price}</span>
          <span className="text-xl text-tomato-9">{salePrice}</span>
        </div>
        <p className="text-s">(Local taxes included)</p>
      </header>
      <main className="flex-1 px-4 pb-8 space-y-4">
        <ProductPhotos
          photos={blueprint.images.map((url, index) => ({
            isDefault: index === 0,
            isPreviewReady: true,
            isDoneTrying: true,
            url,
            base64: url,
            possiblySucceededOnFirstLoad: false,
          }))}
        />
        <div className="space-x-1 space-y-2">
          <h2 className="font-bold">Choose Quantity</h2>
          <QuantitySelector
            quantity={quantity}
            setQuantity={setQuantity}
            maxQuantity={10}
          />
        </div>
        <VariantSelectionSection
          variants={variants}
          selectedVariantId={variantId ?? blueprint.variants[0].id}
          updateVariantId={updateVariantId}
        />
        <div>
          <Collapsible header="Item details">
            {blueprint.itemDetails}
          </Collapsible>
          <Collapsible header="Key features">
            {blueprint.keyFeatures.map((feature) => (
              <p className="mb-4" key={feature}>
                {feature}
              </p>
            ))}
          </Collapsible>
          <Collapsible header="Delivery information">
            Shipping charges and times vary by delivery location. For exact
            details please enter your shipping address on the payment screen.
          </Collapsible>
        </div>
      </main>
      <footer className="text-center space-y-4 p-2 bg-white sticky top-0 bottom-0">
        <p className="text-gray-11">
          By continuing, you agree to our{' '}
          <button className="text-blue-10" onClick={openTermsOfUsePage}>
            Terms of Service
          </button>
        </p>
        <div className="grid grid-cols-2 gap-2">
          <Button onClick={previous} colorVariant="primary:invert">
            Back
          </Button>
          <Button onClick={onNextClick} colorVariant="primary">
            Personalize
          </Button>
        </div>
      </footer>
    </div>
  )
}
