import { useCallback, useEffect, useState } from 'react'
import ApiClient from '../../../ApiClient'

export default function useCrop({
  originalCdnUrl,
  usedCdnUrl,
  api,
}: {
  originalCdnUrl: string | null
  usedCdnUrl: string | null
  api: ApiClient
}) {
  const [confirmedOriginalDataUrl, setOriginalDataUrl] = useState<
    string | null
  >(null)
  const [croppedDataUrl, setCroppedDataUrl] = useState<string | null>(null)
  const [candidateDataUrl, setCandidateDataUrl] = useState<string | null>(null)

  const [isCropping, setIsCropping] = useState(false)

  const init = useCallback(
    (base64: string) => {
      setOriginalDataUrl(base64)
      setCandidateDataUrl(base64)
      setIsCropping(true)
    },
    [setOriginalDataUrl]
  )

  const confirmCrop = useCallback(
    (base64url: string) => {
      setCroppedDataUrl(base64url)
      setOriginalDataUrl(candidateDataUrl)
      setIsCropping(false)
    },
    [, candidateDataUrl, setOriginalDataUrl]
  )

  const openCropDialog = useCallback(() => {
    setIsCropping(true)
  }, [setIsCropping])

  const closeCropDialog = useCallback(() => {
    setIsCropping(false)
    setCandidateDataUrl(null)
    if (!croppedDataUrl && confirmedOriginalDataUrl) {
      setOriginalDataUrl(null)
    }
  }, [setIsCropping, croppedDataUrl, confirmedOriginalDataUrl])

  const reset = useCallback(async (base64: string) => {
    setCandidateDataUrl(base64)
    setIsCropping(true)
  }, [])

  async function convertCdnPhotosToBase64(
    originalCdnUrl: string,
    usedCdnUrl: string
  ) {
    const response = await api.convertCdnPhotosToBase64([
      // using UUIDs here as it is a part of the original conversion API
      // We can probably remove it in a different scope
      { url: originalCdnUrl, uuid: 'ORIGINAL' },
      { url: usedCdnUrl, uuid: 'USED' },
    ])
    setOriginalDataUrl(response[0].url)
    setCroppedDataUrl(response[1].url)
  }

  useEffect(() => {
    if (originalCdnUrl && usedCdnUrl) {
      convertCdnPhotosToBase64(originalCdnUrl, usedCdnUrl)
    }
  }, [])

  return {
    initCrop: init,
    croppedDataUrl,
    isCropping,
    candidateDataUrl,
    setIsCropping,
    confirmCrop,
    resetCrop: reset,
    openCropDialog,
    closeCropDialog,
    urls: {
      candidate: candidateDataUrl,
      cropped: croppedDataUrl,
      original: confirmedOriginalDataUrl,
    },
  }
}
