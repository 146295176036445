import { AxiosInstance } from 'axios'
import {
  CreateOrder,
  CreatePaymentIntent,
  GetBlueprintAndVariants,
  GetBlueprintAnvVariantsResponse,
  GetPaymentIntent,
  GetPrintifyOrder,
  GetProduct,
} from './types/printify'

export class PrintifyApi {
  api: AxiosInstance

  constructor(api: AxiosInstance) {
    this.api = api
  }

  createOrder: CreateOrder = async (payload) => {
    const { data } = await this.api.post(
      '/v1/printify/orders',
      payload.lineItems
    )
    return data
  }

  getOrder: GetPrintifyOrder = async (orderId: string) => {
    const { data } = await this.api.get(`/v1/printify/orders/${orderId}`)
    return data
  }

  getProduct: GetProduct = async (productId: string) => {
    const { data } = await this.api.get(`/v1/printify/products/${productId}`)
    return data
  }

  createPaymentIntent: CreatePaymentIntent = async ({
    baseOrderId,
    quantity,
  }) => {
    const { data } = await this.api.post('/v1/printify/payment', {
      baseOrderId,
      quantity,
    })
    return data
  }

  getPaymentIntent: GetPaymentIntent = async (id) => {
    const { data } = await this.api.get(`/v1/printify/payment/${id}`)
    return data
  }

  getBlueprintsAndVariants: GetBlueprintAndVariants = async (
    blueprintId: number
  ) => {
    const { data } = await this.api.get<GetBlueprintAnvVariantsResponse>(
      `/v1/printify/blueprints/${blueprintId}`
    )

    return data
  }
}
