import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AdvocateLanding from './pages/referral/advocate/AdvocateLanding'
import ApiClient from './ApiClient'
import Redeem from './pages/referral/referred/Redeem'
import RedeemLanding from './pages/referral/referred/RedeemLanding'
import AdvocateRedemptionPage from './pages/referral/advocate/AdvocateRedemptionPage'
import { Toaster } from 'react-hot-toast'
import config from './config'
import RedeemLandingNoReward from './pages/referral/referred/RedeemLandingNoReward'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react' // Provider imports 'rollbar'
import Loop from './pages/loop/Loop'
import LoopAuth from './pages/loop/LoopAuth'
import LoopModal from './pages/loop/Landing'
import LoopFathersDay from './pages/loop/LoopFathersDay'
import LoopGrandparentsDay from './pages/loop/LoopGrandparentsDay'
import LoopMothersDay from './pages/loop/LoopMothersDay'
import { b64Encode, isMaintenanceTimeInEST } from './utils'
import MaintenancePage from './pages/MaintenancePage'
import GcTerms from './pages/referral/GcTerms'
import { lazy } from './components/ReactLazyWithReload'
import LoadingScreen from './components/LoadingScreen'
import LoopStore from './pages/loop/LoopStore'
import { StorefrontClient } from './clients/storefront'
import Clarity from './components/vendor/Clarity'
import FathersDaySpecial from './pages/fathers-day/FathersDaySpecial'
import GrandparentsDaySpecial from './pages/grandparents-day/GrandparentsDaySpecial'
import DivvyUpFathersDay from './pages/fathers-day/DivvyUpFathersDay'
import DivvyUpGrandparentsDay from './pages/grandparents-day/DivvyUpGrandparentsDay'
import PhotoGiftsGrandparentsDay from './pages/grandparents-day/PhotoGiftsGrandparentsDay'
import PhotoGiftsLandingPage from './pages/photo-products/PhotoGiftsLandingPage'
import japaneseThankYouHeaderBg from './assets/japanese_smokescreens/thank_you_header_bg.svg'
import japaneseThankYouImage from './assets/japanese_smokescreens/thank_you_center.png'
import SurveyThankYou from './pages/smokescreen-tests/SurveyThankYou'
import PhotoProductsApp from './pages/photo-products'
import ErrorPage from './pages/ErrorPage'
import PhotoProductsTou from './pages/photo-products/PhotoProductsTou'
import './index.css'

const api = new ApiClient({
  baseURL: import.meta.env.VITE_API_BASE_URL,
})

const storefrontClient = new StorefrontClient({
  storeDomain: import.meta.env.VITE_STORE_DOMAIN,
  publicAccessToken: import.meta.env.VITE_STOREFRONT_PUBLIC_ACCESS_TOKEN,
  productHandle: import.meta.env.VITE_STORE_PRODUCT_HANDLE,
})

const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENVIRONMENT,
}

const {
  familyAlbumAutoLoginUrl,
  familyAlbumDownloadDeepLink,
  supportEmail,
  familyAlbumStoreDeepLink,
  clarityProjectId,
  isPrintifyPreviewLogsActive,
} = config

const navigateToFamilyAlbumStore = () => {
  window.location.assign(familyAlbumStoreDeepLink)
}

const generateReferredUserAutologinUrl = (advocateReferralCode: string) => {
  return `${familyAlbumAutoLoginUrl}?data=${b64Encode(
    JSON.stringify({ advocateReferralCode })
  )}`
}

const generateLoopAutologinUrl = (loopUserId: string) => {
  return `${familyAlbumAutoLoginUrl}?data=${b64Encode(
    JSON.stringify({ loopUserId })
  )}`
}

const GPSTalk = lazy(
  () =>
    import(
      /* webpackChunkName: "GPSTalk" */ './pages/smokescreen-tests/GPSTalk'
    )
)

const Survey = lazy(
  () =>
    import(/* webpackChunkName: "Survey" */ './pages/smokescreen-tests/Survey')
)

function FallbackUi({
  error,
  resetError,
}: {
  error: Error | null
  resetError: () => void
}) {
  const message = error ? error.message : 'An error occurred'
  return (
    <ErrorPage
      error={message}
      message="We are sorry for the inconvenience. Please try again later."
      buttonText="Return to the store page"
      onButtonClick={navigateToFamilyAlbumStore}
    />
  )
}

const router = createBrowserRouter([
  {
    path: '/photo-products/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <ErrorBoundary fallbackUI={FallbackUi}>
          <PhotoProductsApp
            api={api}
            sourcePath="/photo-products"
            isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
          />
        </ErrorBoundary>
      </Suspense>
    ),
  },
  {
    path: '/familyalbum-gps/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="XJMKWKX">
          <GPSTalk api={api} />
        </Survey>
      </Suspense>
    ),
  },
  {
    path: '/user-needs-survey/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="LHN2HKK" />
      </Suspense>
    ),
  },
  {
    path: '/family-priorities-survey/*',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Survey surveyId="D22VZ6T" />
      </Suspense>
    ),
  },
  {
    path: '/survey/thank-you',
    element: (
      <SurveyThankYou
        title="Your feedback has been submitted successfully"
        message="Thank you for completing the survey and helping us improve our services. Your feedback is incredibly valuable to us, and we truly appreciate your time."
      />
    ),
  },
  {
    path: '/survey/thank-you-ss',
    element: (
      <SurveyThankYou
        title="Thanks for your interest!"
        message="Thank you for submitting your responses! We’ll keep you updated with the
        latest news and developments."
      />
    ),
  },
  {
    path: '/survey/thank-you-jp',
    element: (
      <div className="max-w-xl mx-auto h-screen items-center font-hiragino">
        <div
          className="w-full bg-cover text-center pt-12 pb-4 px-4 text-[#202020] -mb-4"
          style={{ backgroundImage: `url(${japaneseThankYouHeaderBg})` }}
        >
          <div className="mb-2 font-bold text-2xl">
            ご回答いただき
            <br />
            ありがとうございました！
          </div>
          <div className="font-normal text-base">
            ご回答いただいた内容は、
            <br />
            今後の商品開発の参考にさせていただきます。
          </div>
        </div>
        <img className="w-full" src={japaneseThankYouImage} alt="" />
        <p className="text-center mt-3 text-base font-normal">
          左上の閉じるをタップして、
          <br />
          終了してください。
        </p>
      </div>
    ),
  },
  {
    path: '/loop/auth',
    element: <LoopAuth />,
  },
  {
    path: '/loop',
    element: <Loop generateLoopAutologinUrl={generateLoopAutologinUrl} />,
  },
  {
    path: '/modal-loop-v2',
    element: (
      <LoopModal
        couponCode="FAMILYALBUM"
        api={api}
        supportEmail={supportEmail}
      />
    ),
  },
  {
    path: '/modal-loop-v3',
    element: (
      <LoopModal
        couponCode="FAMILYALBUM"
        api={api}
        supportEmail={supportEmail}
      />
    ),
  },
  {
    path: '/modal-loop',
    element: (
      <LoopModal
        couponCode="FAMILYALBUM"
        api={api}
        supportEmail={supportEmail}
      />
    ),
  },
  {
    path: '/modal-loop-new-user',
    element: (
      <LoopModal
        couponCode="FAMILYALBUM"
        api={api}
        supportEmail={supportEmail}
      />
    ),
  },
  {
    path: '/loop-store/*',
    element: (
      <>
        {clarityProjectId && <Clarity projectId={clarityProjectId} />}
        <LoopStore api={api} storefrontClient={storefrontClient} />
      </>
    ),
  },
  {
    path: '/modal-loop-mothers-day',
    element: <LoopMothersDay api={api} supportEmail={supportEmail} />,
  },
  {
    path: '/modal-loop-fathers-day',
    element: (
      <LoopFathersDay
        api={api}
        supportEmail={supportEmail}
        couponCode="HAPPYDAD"
      />
    ),
  },
  {
    path: '/modal-loop-grandparents-day',
    element: (
      <LoopGrandparentsDay
        api={api}
        supportEmail={supportEmail}
        couponCode="GRANDJOY"
      />
    ),
  },
  {
    path: '/modal-fathers-day-special',
    element: (
      <FathersDaySpecial
        api={api}
        familyAlbumAutoLoginUrl={familyAlbumAutoLoginUrl}
      />
    ),
  },
  {
    path: '/modal-grandparents-day-special',
    element: (
      <GrandparentsDaySpecial
        api={api}
        familyAlbumAutoLoginUrl={familyAlbumAutoLoginUrl}
      />
    ),
  },
  {
    path: '/divvyup-fathers-day',
    element: <DivvyUpFathersDay api={api} supportEmail={supportEmail} />,
  },
  {
    path: '/divvyup-grandparents-day',
    element: <DivvyUpGrandparentsDay api={api} supportEmail={supportEmail} />,
  },
  {
    path: '/photo-gifts',
    element: (
      <PhotoGiftsLandingPage
        api={api}
        supportEmail={supportEmail}
        printifyProductsListLink="/photo-products"
      />
    ),
  },
  {
    path: '/photo-gifts-grandparents-day',
    element: (
      <PhotoGiftsGrandparentsDay
        api={api}
        supportEmail={supportEmail}
        printifyProductsListLink="/photo-products/add"
      />
    ),
  },
  {
    path: '/refer/:advocateReferralCode',
    element: (
      <AdvocateLanding
        supportEmail={supportEmail}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/advocate/:advocateReferralCode/redemptions/:redemptionId',
    element: (
      <AdvocateRedemptionPage
        supportEmail={supportEmail}
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/advocate/:advocateReferralCode/redemptions/:redemptionId/past',
    element: (
      <AdvocateRedemptionPage
        supportEmail={supportEmail}
        isPastRedemtpion
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
        api={api}
      />
    ),
  },
  {
    path: '/redeem',
    element: (
      <Redeem
        supportEmail={supportEmail}
        api={api}
        familyAlbumStoreDeepLink={familyAlbumStoreDeepLink}
      />
    ),
  },
  {
    path: '/r/:advocateReferralCode',
    element: (
      <RedeemLanding
        generateReferredUserAutologinUrl={generateReferredUserAutologinUrl}
        familyAlbumDownloadDeepLink={familyAlbumDownloadDeepLink}
      />
    ),
  },
  {
    path: '/r0/:advocateReferralCode',
    element: (
      <RedeemLandingNoReward
        generateReferredUserAutologinUrl={generateReferredUserAutologinUrl}
      />
    ),
  },
  {
    path: 'referral/gc-terms/:currencyCode?',
    element: <GcTerms />,
  },
  {
    path: 'referral/gc-terms/:currencyCode?',
    element: <GcTerms />,
  },
  {
    path: 'photo-products-tou',
    element: <PhotoProductsTou />,
  },
  {
    path: '*',
    element: <ErrorPage error={404} message="Page Not Found" />,
  },
])

if (isMaintenanceTimeInEST()) {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <MaintenancePage />
  )
} else {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <RollbarProvider config={rollbarConfig}>
      <Toaster position="top-center" />
      <RouterProvider router={router} />
    </RollbarProvider>
  )
}
