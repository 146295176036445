import { useEffect } from 'react'
import cx from 'classnames'
import Button from '../../shared/buttons/Button'
import { GetPrintifyOrderFunction } from '../../clients/fagl-server/types/printify'
import { PreviewUrlState } from './usePrintify'
import ProductPhotos from './components/ProductPhotos'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

export default function PreviewPage({
  next,
  previous,
  previewPhotosList,
  showNotAllLoadedError,
  regeneratePreview,
  openLogsPage,
  isPrintifyPreviewLogsActive,
  isAddingItemToCart,
}: {
  next: () => void
  previous: () => void
  previewPhotosList: PreviewUrlState[]
  showNotAllLoadedError: boolean
  regeneratePreview: () => void
  openLogsPage: () => void
  isPrintifyPreviewLogsActive: boolean
  isAddingItemToCart: boolean
}) {
  const footer = (
    <footer className="text-center space-y-4 p-2 bg-white sticky w-full top-full">
      <div
        className={cx(
          'grid gap-2',
          isPrintifyPreviewLogsActive ? 'grid-cols-3' : 'grid-cols-2'
        )}
      >
        {isPrintifyPreviewLogsActive && (
          <Button onClick={() => openLogsPage()} colorVariant="primary:invert">
            View Logs
          </Button>
        )}

        <Button onClick={() => previous()} colorVariant="primary:invert">
          Back
        </Button>
        <Button
          disabled={isAddingItemToCart}
          isLoading={isAddingItemToCart}
          onClick={() => next()}
          colorVariant="primary"
        >
          Continue
        </Button>
      </div>
    </footer>
  )

  if (showNotAllLoadedError) {
    return (
      <div className="bg-gray-2 h-screen">
        <header className="px-4 space-y-4 pt-6">
          <ExclamationCircleIcon className="h-12 w-12 mx-auto" />
          <h1 className="font-bold text-center pb-4">
            Unable to load preview images
          </h1>
        </header>
        <div className="px-4 space-y-6">
          <div className="text-gray-11 space-y-2">
            <p>An error occurred while loading your preview.</p>
            <p>
              Please tap 'Retry Preview' or select 'Continue' to proceed without
              previewing your item.
            </p>
            <p>
              Don't worry — your order will still include the selected image!
            </p>
          </div>
          <Button
            className="w-full"
            onClick={() => regeneratePreview()}
            colorVariant="primary:invert"
          >
            Retry Preview
          </Button>
        </div>
        {footer}
      </div>
    )
  }

  return (
    <div className="bg-gray-2 h-screen">
      <header className="px-4">
        <h1 className="font-bold text-xl py-8">Preview your design</h1>
      </header>
      <div className="px-4">
        <ProductPhotos photos={previewPhotosList} />
      </div>
      {footer}
    </div>
  )
}
