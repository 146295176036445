import { useCallback, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import SelectPhotoPage from './SelectPhoto'
import ProductPage from './Product'
import PersonalizePage from './Personalize'
import PreviewPage from './Preview'
import LoadingOverlay from './components/LoadingOverlay'
import ApiClient from '../../ApiClient'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import usePrintify, { useBlueprintId } from './usePrintify'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import LogsPanel from './LogsPanel'
import {
  AddItemToCartPayload,
  addItemToCartPayloadSchema,
  CartItem,
} from '../../clients/fagl-server/types/photoProductsCart'
import useCrop from './hooks/useCrop'

export default function CreateFlow({
  api,
  editedCartItem,
  openTermsOfUsePage,
  navigateBackToSource,
  isPrintifyPreviewLogsActive,
  addItemToCart,
  cancelCartItemEdit,
}: {
  api: ApiClient
  editedCartItem: CartItem | null
  openTermsOfUsePage: () => void
  navigateBackToSource: () => void
  isPrintifyPreviewLogsActive: boolean
  addItemToCart: (payload: AddItemToCartPayload) => void
  cancelCartItemEdit: () => void
}) {
  const blueprintId = useBlueprintId()
  const {
    isLoading,
    orderState,
    blueprint,
    createBaseOrder,
    updateQuantityAndVariant,
    previewPhotosList,
    isCreatingBaseOrder,
    isGettingOrder,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    baseOrderId,
    currency,
    selectedVariant,
    addItemToCartPayload,
    uploadOriginalImage,
    variantId,
    variants,
    blueprintLoadError,
    resetState,
    usedImageCdnUrl,
    originalImageCdnUrl,
  } = usePrintify(blueprintId, api, editedCartItem)
  const isEditMode = !!editedCartItem
  const navigate = useNavigate()

  const {
    photoProducts: {
      printify: { recordDidTapPersonalize, recordDidTapPreview },
    },
  } = useRecordUserAction(api)

  const [isAddingItemToCart, setIsAddingItemToCart] = useState(false)
  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)

  const {
    croppedDataUrl,
    isCropping,
    initCrop,
    resetCrop,
    confirmCrop,
    openCropDialog,
    closeCropDialog,
    urls,
  } = useCrop({
    originalCdnUrl: originalImageCdnUrl || null,
    usedCdnUrl: usedImageCdnUrl || null,
    api,
  })

  const onPhotoSelected = useCallback(
    (base64: string) => {
      initCrop(base64)
      navigate('personalize')
    },
    [navigate]
  )

  const onCropConfirmed = useCallback(
    (base64: string) => {
      if (urls.candidate) {
        uploadOriginalImage(urls.candidate)
      }
      confirmCrop(base64)
      navigate('personalize')
    },
    [navigate, uploadOriginalImage, urls.candidate, confirmCrop]
  )

  const navigateToPayment = useCallback(
    (path: string = '') => {
      navigate(`payment${path}`)
    },
    [navigate, baseOrderId]
  )

  const onPersonalizeCompleted = useCallback(
    async (variantId: number | null) => {
      recordDidTapPreview({
        blueprintId,
      })

      if (!croppedDataUrl) {
        return
      }

      navigate('preview')
      await createBaseOrder(croppedDataUrl, variantId)
    },
    [
      createBaseOrder,
      navigate,
      recordDidTapPreview,
      blueprintId,
      navigateToPayment,
    ]
  )

  const onPreviewCompleted = useCallback(async () => {
    const result = addItemToCartPayloadSchema.safeParse(addItemToCartPayload)

    if (result.success) {
      setIsAddingItemToCart(true)
      await addItemToCart(result.data)
      resetState()
      setIsAddingItemToCart(false)
    } else {
      console.error(result.error)
    }
  }, [blueprintId, addItemToCartPayload, resetState])

  const onProductSelectionCompleted = useCallback(
    (payload: { quantity: number; variantId: number }) => {
      updateQuantityAndVariant(payload.quantity, payload.variantId)
      recordDidTapPersonalize({
        blueprintId,
      })
      if (croppedDataUrl) {
        navigate('personalize')
      } else {
        navigate('select-photo')
      }
    },
    [
      navigate,
      updateQuantityAndVariant,
      recordDidTapPersonalize,
      croppedDataUrl,
      blueprintId,
    ]
  )

  const priceFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter(currency),
    [currency]
  )

  const formattedPrices = useMemo(() => {
    const zeroPrice = `${priceFormatter(0)} ${currency}`
    return {
      price: selectedVariant?.price
        ? `${priceFormatter(selectedVariant.price / 100)} ${currency}`
        : zeroPrice,
      salePrice: selectedVariant?.salePrice
        ? `${priceFormatter(selectedVariant.salePrice / 100)} ${currency}`
        : zeroPrice,
    }
  }, [
    selectedVariant?.price,
    selectedVariant?.salePrice,
    priceFormatter,
    currency,
  ])

  if (isLoading) {
    return <LoadingOverlay>Loading product information</LoadingOverlay>
  }

  if (!blueprint) {
    if (blueprintLoadError === 'NOT_FOUND') {
      throw new Error('Product not found')
    }
    throw new Error('Something went wrong')
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              initialQuantity={orderState.quantity}
              blueprint={blueprint}
              variants={variants}
              initialVariantId={variantId}
              next={onProductSelectionCompleted}
              previous={navigateBackToSource}
              updateQuantityAndVariant={updateQuantityAndVariant}
            />
          }
        />
        <Route
          path="select-photo"
          element={
            <SelectPhotoPage
              api={api}
              next={onPhotoSelected}
              previous={() => navigate('.')}
            />
          }
        />
        <Route
          path="personalize"
          element={
            <PersonalizePage
              key={variantId}
              isEditMode={isEditMode}
              cancelCartItemEdit={cancelCartItemEdit}
              onNewPhotoSelected={resetCrop}
              api={api}
              urls={urls}
              isCropping={isCropping}
              aspectRatio={selectedVariant?.aspectRatio || 1}
              confirmCrop={onCropConfirmed}
              openCropDialog={openCropDialog}
              closeCropDialog={closeCropDialog}
              next={onPersonalizeCompleted}
              initialVariantId={variantId}
              variants={variants}
              previous={() => navigate('select-photo')}
            />
          }
        />
        <Route
          path="preview"
          element={
            <PreviewPage
              isAddingItemToCart={isAddingItemToCart}
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={() => setIsLogsPageVisible(true)}
              showNotAllLoadedError={showNotAllLoadedError}
              previewPhotosList={previewPhotosList}
              previous={() => navigate('personalize')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
      </Routes>
      {(isCreatingBaseOrder || isGettingOrder || isGettingPreview) && (
        <LoadingOverlay opaque animationType="progress-bar">
          Your product preview is being generated. This may take a few moments.
        </LoadingOverlay>
      )}
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )
}
