import { useCallback, useEffect, useState } from 'react'
import { keyBy, pull } from 'lodash'
import ApiClient, {
  FamilyAlbumPhotoSummaryResponse,
  FaglFamilyAlbumMediaFilter,
  MediaFile,
} from '../../../ApiClient'

type Query = {
  filter: FaglFamilyAlbumMediaFilter
  familyAlbumFamilyId: number | null
}

const initialQuery: Query = {
  filter: FaglFamilyAlbumMediaFilter.ALL,
  familyAlbumFamilyId: null,
}

const useFamilyAlbumPhotos = (api: ApiClient) => {
  const [isLoadingSummary, setIsLoadingSummary] = useState(true)
  const [query, setQuery] = useState<Query>(initialQuery)
  const [cursor, setCursor] = useState<string>('')
  const [isLoadingFamilyPhotos, setIsLoadingFamilyPhotos] = useState(false)
  const [hasNext, setHasNext] = useState(false)
  const [photoSummary, setPhotoSummary] =
    useState<FamilyAlbumPhotoSummaryResponse>([])

  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([])

  const [familyPhotos, setFamilyPhotos] = useState<MediaFile[]>([])

  const [error, setError] = useState('')
  const loadPhotoSummary = async () => {
    try {
      const response = await api.getFamilyAlbumPhotosSummary()
      setPhotoSummary(response)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'ERROR')
    } finally {
      setIsLoadingSummary(false)
    }
  }

  const updateQuery = useCallback((newQuery: Query) => {
    setFamilyPhotos([])
    setSelectedPhotos([])
    setCursor('')
    setQuery(newQuery)
  }, [])

  const resetPhotos = useCallback(() => {
    setFamilyPhotos([])
    setSelectedPhotos([])
    setCursor('')
  }, [])

  const loadPhotosForFamilyId = useCallback(
    async (loadQuery: Query, cursor: string) => {
      if (!loadQuery.familyAlbumFamilyId) {
        return
      }

      setIsLoadingFamilyPhotos(true)

      try {
        const response = await api.getFamilyAlbumPhotosForFamilyId(
          loadQuery.familyAlbumFamilyId,
          {
            filter: loadQuery.filter,
            cursor,
          }
        )

        setCursor(response.cursor)
        setHasNext(response.hasNext)
        setFamilyPhotos([...familyPhotos, ...response.mediaFiles])
      } catch (err) {
        setError(err instanceof Error ? err.message : 'ERROR')
      } finally {
        setIsLoadingFamilyPhotos(false)
      }
    },
    [familyPhotos]
  )

  useEffect(() => {
    loadPhotoSummary()
  }, [])

  const convertToBase64 = useCallback(async () => {
    const photosByUuid = keyBy(familyPhotos, 'uuid')

    const base64EncodedImages = await api.convertCdnPhotosToBase64(
      selectedPhotos.map((uuid) => ({
        url: photosByUuid[uuid].url,
        uuid,
      }))
    )

    return base64EncodedImages
  }, [selectedPhotos, familyPhotos])

  const togglePhotoSelection = useCallback(
    (uuid: string) => {
      if (selectedPhotos.includes(uuid)) {
        setSelectedPhotos([])
      } else {
        setSelectedPhotos([uuid])
      }
    },
    [selectedPhotos]
  )

  const loadMore = useCallback(() => {
    loadPhotosForFamilyId(query, cursor)
  }, [loadPhotosForFamilyId, query, cursor])

  return {
    photoSummary,
    isLoadingSummary,
    loadMore,
    updateQuery,
    resetQuery: useCallback(() => {
      updateQuery(initialQuery)
    }, [updateQuery]),
    familyPhotos,
    loadPhotosForFamilyId,
    currentQuery: query,
    selectedPhotos,
    convertToBase64,
    togglePhotoSelection,
    error,
    resetPhotos,
    hasNext,
    isLoadingFamilyPhotos,
    albumName:
      photoSummary.find((family) => family.id === query.familyAlbumFamilyId)
        ?.name || '',
  }
}

export default useFamilyAlbumPhotos
