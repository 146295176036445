import { PrintifyBlueprintVariant } from '../../../clients/fagl-server/types/printify'
import VariantSelectButton from './VariantSelectButton'
import VariantSizeSelector from './VariantSizeSelector'

export default function VariantSelector({
  title,
  option,
  variants,
  selectedVariantId,
  updateVariantId,
  additionalInfo,
}: {
  title: string
  option: 'size' | 'color'
  variants: PrintifyBlueprintVariant[]
  selectedVariantId: number
  updateVariantId: (variantId: number) => void
  additionalInfo?: string
}) {
  return (
    <div className="space-x-1 space-y-2">
      <div className="flex items-baseline">
        <h2 className="font-bold">{title}</h2>
        {additionalInfo && (
          <span className="text-gray-11 text-s ml-1">({additionalInfo})</span>
        )}
      </div>
      {option === 'size' && (
        <VariantSizeSelector
          variants={variants}
          updateVariantId={updateVariantId}
          selectedVariantId={selectedVariantId}
        />
      )}
      {option === 'color' && (
        <ul className="flex flex-wrap gap-3">
          {variants.map((variant) => {
            const isSelected = selectedVariantId === variant.id
            const colorName = variant.options.color as string
            const colorValue = variant.options.hexColor as string
            return (
              <li key={variant.id}>
                <VariantSelectButton
                  isSelected={isSelected}
                  colorName={colorName}
                  colorValue={colorValue}
                  variantId={variant.id}
                  updateVariantId={updateVariantId}
                />
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
